import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable-resource',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('resource-header',{attrs:{"back-to":{ name: 'sale-taxes' },"header":_vm.$t('Add new store')}}),_c(VDivider)]},proxy:true},{key:"scrollable.content",fn:function(){return [_c(VStepper,{attrs:{"non-linear":"","flat":"","vertical":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_vm._l((_vm.sections),function(section,index){return [_c(VStepperStep,{key:("step-" + (index + 1)),attrs:{"complete":_vm.step > index + 1,"step":index + 1,"editable":_vm.step > index + 1}},[_vm._v(" "+_vm._s(_vm.$t(section.header))+" ")]),_c(VStepperContent,{key:("step-content-" + (index + 1)),attrs:{"step":index + 1}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.formAction.apply(null, arguments)}}},[_c('div',{staticClass:"my-4"},[(index === _vm.step - 1)?_c(section.component,{tag:"component",staticClass:"d-flex flex-column flex-gap-16",attrs:{"errors":_vm.errors},on:{"input":_vm.onItemChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.isLastStep ? !_vm.isValid : Object.values(_vm.sectionErrors)[index],"loading":_vm.loading,"type":"submit","depressed":""}},[_vm._v(" "+_vm._s(_vm.isLastStep ? _vm.$t('Create store') : _vm.$t('Continue'))+" ")])],1)],1)])],1)]})],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'store-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new store'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'store-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"color":item.color,"large":""}},[_vm._v("bookmark")])]}},{key:"item.domain.default",fn:function(ref){
var item = ref.item;
return [_c('external-link',{attrs:{"label":item.domain.default,"value":item.domain.default}})]}},{key:"item.domain.custom",fn:function(ref){
var item = ref.item;
return [(item.domain.custom)?_c('external-link',{attrs:{"label":item.domain.custom,"value":item.domain.custom}}):_vm._e()]}},{key:"item.theme",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"palette","icon":"","label":_vm.$t('Select theme'),"disabled":_vm.grid.loading,"to":{ name: 'select-theme', params: { store: item.id } }}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'store-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('store-action-delete',{attrs:{"icon":"","value":item},on:{"success":_vm.refetchStores,"error":_vm.onRequestError}})]}}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Stores') },false),[_c('div',{staticStyle:{"display":"none"}},[_c('ThemeChanger')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
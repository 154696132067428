import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCombobox,_vm._b({attrs:{"outlined":"","label":_vm.$t('Flag'),"items":_vm.items,"item-text":"name","item-value":"emoji","return-object":"","loading":_vm.loading,"hide-details":"auto","menu-props":{
      closeOnContentClick: true,
      disableKeys: true,
      maxHeight: 304
    },"search-input":_vm.searchCountry},on:{"update:searchInput":function($event){_vm.searchCountry=$event},"update:search-input":function($event){_vm.searchCountry=$event},"input":_vm.onLanguageSelect},scopedSlots:_vm._u([(!_vm.searchCountry && !_vm.selectedCountry)?{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"d-flex align-center mx-4"},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('Suggested')))]),_c(VDivider,{staticClass:"mx-4"})],1),_vm._l((_vm.suggestedCountries),function(suggested,index){return _c('div',{key:("dropdown-" + index),class:_vm.suggestedListItemClasses,attrs:{"role":"option","type":"button"},on:{"click":function($event){return _vm.onLanguageSelect(suggested)}}},[_c('span',[_vm._v(_vm._s(suggested.emoji)+" "+_vm._s(suggested.name))])])}),_c('div',{staticClass:"d-flex align-center mx-4"},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t('Alphabetically')))]),_c(VDivider,{staticClass:"mx-4"})],1)]},proxy:true}:null,{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.emoji)+" "+_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.emoji)+" "+_vm._s(item.name))])]}}],null,true),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}},'v-combobox',_vm.$attrs,false)),_c('div',{staticClass:"d-flex align-center flex-gap-8"},[_c(VChipGroup,{staticClass:"flex-gap-8"},_vm._l((_vm.suggestedCountries),function(suggested,index){return _c(VChip,{key:("chip-" + index),on:{"click":function($event){return _vm.onLanguageSelect(suggested)}}},[_vm._v(" "+_vm._s(suggested.emoji)+" "+_vm._s(suggested.name)+" ")])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }